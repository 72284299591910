<template>
  <div>
    <div class="card-toolbar mb-5 d-flex justify-content-between">
      <div>
        <router-link v-if="$can('items.create')" class="btn btn-primary font-weight-bolder" to="/items/items/create">
          <v-icon>mdi-plus</v-icon>
          {{ $t('items.new_item') }}
        </router-link>
        <button class="btn btn-outline-primary font-weight-bolder ml-5" @click="openAdvancedSearch">
          <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
        </button>
      </div>
      <div>
        <router-link v-if="$can('items.export_import_items')" class="btn btn-primary font-weight-bolder h-100"
                     to="/items/import-items">
          {{ $t('MENU.export_import_items') }}
        </router-link>
      </div>
    </div>
    <div v-if="showAdvancedSearch" class="card card-custom mb-5">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_name">{{ $t('items.name') }}</label>
              <input id="f_name" v-model="filters.name" class="form-control" type="text">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="sku_code">{{ $t('items.sku_code') }}</label>
              <input id="sku_code" v-model="filters.sku_code" class="form-control" type="text">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('items.category') }}</label>
              <treeselect
                  :load-options="loadOptions"
                  :multiple="false"
                  :no-children-text="$t('No_sub_options')"
                  :no-options-text="$t('No_options_available')"
                  :no-results-text="$t('No_results_found')"
                  :options="categories"
                  :placeholder="$t('Select')"
                  :searchable="true"
                  :show-count="true"
                  :value="filters.category_id"
                  @input="updateValue">
              </treeselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('items.unit') }}</label>
              <multiselect v-model="unit"
                           :multiple="false"
                           :options="units"
                           :placeholder="$t('items.unit')"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           :taggable="false"
                           label="name"
                           track-by="id">
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">
                {{ $t('status') }}
              </label>
              <select id="f_status" v-model="filters.is_active" class="custom-select" name="" type="text">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :key="index" :value="row.id">{{ row.text }}</option>
              </select>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="barcode">{{ $t('barcode') }}</label>
              <input id="barcode" v-model="filters.barcode" class="form-control" type="text">
            </div>
            <div class="form-group d-inline-flex col-md-6 mt-10">
              <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i>
                {{ $t('search') }}
              </b-button>
              <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i>
                {{ $t('reset_search') }}
              </b-button>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">

      <div class="card-body">
        <div class="mb-5">

          <div class="d-flex justify-content-between">
            <div>
              <b-checkbox v-model="checkAll">{{ $t('select_all') }}</b-checkbox>
            </div>
            <div class="d-flex justify-content-end">
              <div style="margin-right: 0.5rem !important;margin-left: 0.5rem !important;">
                <b style="line-height: 2.9;">{{ $t('total_items') }}: {{ dataCount }}</b>
              </div>
              <div style="margin-right: 0.5rem !important;margin-left: 0.5rem !important;">
                <custom-export-data v-if="$can('items.export_data')" :data-list="dataList" :fields="json_fields"
                                    :file-name="$t('MENU.Items')" :filters="filters"
                                    :route="mainRoute"></custom-export-data>
              </div>
              <button v-if="$can('items.delete_all_items_system')" :disabled="checkedRows <= 0"
                      class="btn btn-danger" style="margin-right: 0.5rem !important;margin-left: 0.5rem !important;" @click="deleteAllItems">
                {{ $t('delete_selected') }}
              </button>
              <button v-if="$can('items.delete_all_items_system')" class="btn btn-danger" @click="deleteAllItemsSystem">
                {{ $t('delete_all_items_system') }}
              </button>


            </div>


          </div>

          <div>

          </div>
        </div>

        <v-server-table ref="table" :class="'dataTable table-row-dashed'" :columns="columns" :options="options">
          <template slot="#" slot-scope="props">
            <span>{{props.index}}</span>
          </template>
          <template slot="selected" slot-scope="props">
            <b-checkbox v-model="checkedRows" :value="props.row.id"></b-checkbox>
            <!--                        <input type="checkbox" v-model="checkedRows">-->
          </template>
          <template slot="name" slot-scope="props">
            <div class="d-flex justify-content-center">
              <img :src="props.row.image_url" alt="" style="width: 70px; height: 70px;">
              <div class="pl-2 pr-2 text-dark">
                <p v-if="props.row.name" class="mb-1">{{ $t('items.name') }}: {{ props.row.name }}</p>
                <p v-if="props.row.category_name" class="mb-1">{{ $t('items.category') }}: {{
                    props.row.category_name
                  }}</p>
              </div>
            </div>
          </template>
          <template slot="status" slot-scope="props">
            <b-form-checkbox v-if="$can('items.change_status')" :key="props.row.id"
                             v-model="props.row.is_active" :name="'check-button'+props.row.id"
                             size="lg" switch @change="changeStatus(props.row.id, props.row.is_active)"></b-form-checkbox>
            <b-form-checkbox v-else :key="props.row.id" v-model="props.row.is_active" :name="'check-button'+props.row.id" size="lg"
                             switch></b-form-checkbox>
          </template>
          <template slot="actions" slot-scope="props">
            <v-icon v-if="$can('items.update')" v-b-tooltip.hover :title="$t('edit')" class="mr-2 text-info" color="blue darken-2"
                    small @click="editItem(props.row)">mdi-pencil
            </v-icon>
            <v-icon v-if="$can('items.delete')" v-b-tooltip.hover :title="$t('delete')" class="text-danger" color="red darken-2"
                    small @click="deleteItem(props.row)">mdi-delete
            </v-icon>
          </template>
        </v-server-table>


        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";


export default {
  name: "index",
  components: {},
  data() {
    return {

      mainRoute: 'items/items',
      subMainRoute: 'items/item',
      mainRouteDependency: 'base/dependency',
      showAdvancedSearch: false,
      dataList: [],
      dataCount: 0,
      checkedRows: [],

      filters: {
        name: '',
        sku_code: '',
        is_active: '',
        item_type: 1,
        category_id: null,
        barcode: null,
        unit_id: null,
      },

      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],
      columns: ['selected', '#','id','name','unit_name', 'sku_code', 'created_at', 'status', 'actions'],

      disabledButton: false,
      validation: [],
      checkAll: false,
      categories: [],
      units: [],
      unit: null,


    }
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('items.name')] = 'name';
      fields[this.$t('items.sku_code')] = 'sku_code';
      fields[this.$t('items.unit')] = 'unit_name';
      fields[this.$t('items.sale_price')] = 'sale_price';
      fields[this.$t('items.min_sale_price')] = 'min_sale_price';
      fields[this.$t('items.purchase_price')] = 'purchase_price';
      fields[this.$t('items.discount')] = 'discount';
      fields[this.$t('items.profit_margin')] = 'profit_margin';
      fields[this.$t('items.special_description')] = 'special_description';
      fields[this.$t('items.description')] = 'description';
      fields[this.$t('created_at')] = 'created_at';

      return fields;
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          id: that.$t('id'),
          selected: '',
          name: that.$t('items.name'),
          unit_name: that.$t('items.unit'),
          sku_code: that.$t('items.sku_code'),
          created_at: that.$t('created_at'),
          status: that.$t('status'),
          actions: that.$t('actions'),

        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: 0,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataCount = resp.data.data.total;
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        },
      }

    },
  },
  watch: {
    unit: function (val){
      if (val){
        this.filters.unit_id = val.id;
      }else{
        this.filters.unit_id = null;
      }
    },
    checkAll: function (val) {
      if (val) {
        this.dataList.forEach((row) => {
          if (!this.checkedRows.includes(row.id))
            this.checkedRows.push(row.id);
        });
      } else {
        this.checkedRows = [];
      }
    }
  },
  mounted() {
    // let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Items")}]);
    this.getCategories();
    this.getUnits();
  },
  methods: {
    getFetch() {
      this.$refs.table.refresh();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = null;
      this.filters.sku_code = null;
      this.filters.is_active = null;
      this.filters.category_id = null;
      this.filters.barcode = null;
      this.filters.unit_id = null;
      this.unit = null;

      this.$refs.table.refresh();
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    editItem(item) {
      this.$router.push({name: 'items.edit', params: {id: item.id}});
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    deleteAllItems() {
      let ids = this.checkedRows;
      this.$confirmAlert('', this.actionDeleteAllItems, ids);
    },
    actionDeleteAllItems(ids) {
      ApiService.patch(this.subMainRoute + "/delete_all", {
        id: ids,
      }).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message);
        this.checkedRows = [];
        this.checkAll = false;
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    changeStatus(id, status) {
      ApiService.patch(this.subMainRoute + '/change-status/' + id, {
        is_active: (status ? '1' : '0'),
        item_type: 1,
      }).then(response => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
        this.checkedRows = [];
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
    updateValue(value) {
      this.filters.category_id = value
    },
    loadOptions() {
    },
    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },

    deleteAllItemsSystem() {
      this.$confirmAlert(this.$t('do_you_want_to_delete_all_system_products'), this.actionDeleteAllItemsSystem);
    },
    actionDeleteAllItemsSystem() {
      ApiService.patch(this.subMainRoute + "/delete_all_system/" + this.filters.item_type).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    getUnits() {
      ApiService.get(`${this.mainRouteDependency}/units`).then((response) => {
        this.units = response.data.data;
      });
    },
  },
};
</script>
